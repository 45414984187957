import React from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'
import { graphql, Link} from 'gatsby'
import Gallery from "../components/Gallery/gallery"
import { isFirefox, isIOS } from "react-device-detect";
import { canUseWebP } from "react-img-webp";

export default ({ data }) => (
  <article className="sheet">
    <HelmetDatoCms seo={data.datoCmsWork.seoMetaTags} />
    <div className="sheet__inner">
      <h1 className="sheet__title">{data.datoCmsWork.title}</h1>
      <div
        className="sheet__lead"
        dangerouslySetInnerHTML={{
          __html: data.datoCmsWork.excerpt,
        }}
      />
      <div className="sheet__slider">
      </div>
    </div>
    <div className="sheet__gallery">
      {(canUseWebP() || (isFirefox && !isIOS)) ? 
        <Img className={data.datoCmsWork.invertCoverImage ? 'invert' : ''} backgroundColor="#3b6a6f82" fluid={data.datoCmsWork.coverImage.webp} />
        : 
        <Img className={data.datoCmsWork.invertCoverImage ? 'invert' : ''} backgroundColor="#3b6a6f82" fluid={data.datoCmsWork.coverImage.auto} />
      }
    </div>
    <div className="sheet__inner">
      <div className="facts">
        {data.datoCmsWork.facts.map(({...fact}) => (
          <div className="fact">
            <strong>{fact.function}: </strong>
            {fact.link ? <a target="_blank" rel="noopener noreferrer" href={fact.link}>{fact.name}</a> : <span>{fact.name}</span>}
          </div>
        ))}
      </div>
      <div
        className="sheet__body"
        dangerouslySetInnerHTML={{
          __html: data.datoCmsWork.description,
        }}
      />
    </div>
    {(canUseWebP() || (isFirefox && !isIOS)) ? 
      <Gallery images={data.datoCmsWork.webp}/>
      :
      <Gallery images={data.datoCmsWork.auto}/>
    }
    {data.datoCmsWork.video !== null && 
      <div className="video__vimeo">
        <iframe title="video" src={`https://player.vimeo.com/video/${data.datoCmsWork.video.providerUid}`} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
    }
    {data.allDatoCmsWork.edges
    .filter(({ node }) => (
      data.datoCmsWork.slug === node.slug
    ))
    .map(({next, previous}) => (
      <div className="work__nav">
        <span className="work__nav__link prev">
          {previous && <Link to={`/arbeiten/${previous.slug}`}>
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" /></svg>
            {previous.title}
            </Link>}
        </span>
        <span className="work__nav__link next">
          {next && <Link to={`/arbeiten/${next.slug}`}>
            {next.title}
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" /></svg>
          </Link>}
        </span>
      </div>
    ))
    }
  </article>
)

export const query = graphql`
  query WorkQuery($slug: String!) {
    datoCmsWork(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      slug
      excerpt
      auto: gallery {
        author
        fluid(maxWidth: 1440, imgixParams: { auto: "compress, format" }) {
          srcSet
          ...GatsbyDatoCmsSizes_noBase64
        }
      }
      webp: gallery {
        author
        fluid(maxWidth: 1440, imgixParams: { fm:"webp", auto: "compress" }) {
          srcSet
          ...GatsbyDatoCmsSizes_noBase64
        }
      }
      facts {
        function
        name
        link
      }
      video {
        providerUid
      }
      invertCoverImage
      description
      coverImage {
        url
        auto: fluid(maxWidth: 600, imgixParams: {bg: "white", auto: "compress, format" }) {
          ...GatsbyDatoCmsSizes_noBase64
        }
        webp: fluid(maxWidth: 600, imgixParams: {bg: "white", fm: "webp", auto: "compress" }) {
          ...GatsbyDatoCmsSizes_noBase64
        }
      }
    }
    allDatoCmsWork(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          title
          slug
        }
        previous {
          title
          slug
        }
        next {
          title
          slug
        }
      }
    }
  }
`
