import React from "react"
import Img from "gatsby-image"

const NonStretchedImage = props => {
    let normalizedProps = props
    if (props.fluid && props.fluid.sizes) {
        normalizedProps = {
            ...props,
            style: {
                ...(props.style || {}),
                maxWidth: 1440,
                maxHeight: "100%",
                height: "auto",
                width: "100%",
                margin: "0 auto", // Used to center the image
            },
        }
    }

    return <Img backgroundColor="#3b6a6f82" {...normalizedProps} />
}
export default NonStretchedImage
