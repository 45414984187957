import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import { withStyles, ButtonBase } from "@material-ui/core"

const style = theme => ({
    previewButton: {
        display: "block",
        background: "transparent",
        border: "none",
        padding: 0,
        width: "100%",
        height: "auto",
        margin: "20px 0",
    },
})

const ThumbGrid = ({ images, handleOpen, classes }) => {
    return images.map((image, i) => (
        <div>
            <ButtonBase
                onClick={handleOpen(i)}
                className={classes.previewButton}
                key={i}
                aria-label="openLightbox"
            >
                <Img
                    fluid={image.fluid}
                    className={classes.thumbnail}
                    backgroundColor="#3b6a6f82"
                />
            </ButtonBase>
        </div>
    ))
}

ThumbGrid.propTypes = {
    classes: PropTypes.object,
    images: PropTypes.array,
}
export default withStyles(style)(ThumbGrid)
